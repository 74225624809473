<template>
  <!-- 诉讼信息 -->
  <div>
    <div class="topBtn">
      <!-- <div class="btnGroup">
        <base-button label="同步天眼查" style="margin-right:10px" v-if="type!=='details'"  @click.prevent="getSynchronization" />
           <el-upload
                action=""

                :http-request="function (url) {
          return importFile(url);
        }"
                :show-file-list="false"
                accept=".xls,.xlsx"
                v-if="type!=='details'"
              >
                <base-button label="导入"  />
              </el-upload>
      </div> -->
      <el-upload
        action=""
        :http-request="function (url) {
          return importFile(url);
        }"
        :show-file-list="false"
        accept=".xls,.xlsx"
        v-if="type!=='details'"
      >
        <icon-button content="导入" icon="iconfont iconshangchuan"></icon-button>
      </el-upload>
      <icon-button content="同步天眼查" icon="iconfont iconshuaxin" v-if="type!=='details'"  @click.prevent="getSynchronization"></icon-button>
      <div class="addBtn" @click="add" v-if="type!=='details'">
        <i class="el-icon-plus"></i>
      </div>
    </div>
    <base-table
      :columns="tableConfig"
      :showPage="false"
      :tableAttrs="{
        data: tableData,
        stripe: true,
      }"
      :is-caculate-height="false"
      ref="tableData"
      style="margin-top: 10px"
    >
      <template slot="code" slot-scope="scope">{{ scope.$index + 1 }}</template>
      <template slot="caseNumber" slot-scope="scope">
        <sz-input v-model="scope.row.caseNumber" placeholder="请输入" :readonly='!!scope.row.keyId' v-if="type!=='details'"></sz-input>
        <div v-else>{{scope.row.caseNumber}}</div>
      </template>
      <template slot="causeAction" slot-scope="scope"
        ><sz-input v-model="scope.row.causeAction" placeholder="请输入" v-if="type!=='details'"></sz-input
      >
      <div v-else>{{scope.row.causeAction}}</div>
      </template>
      <template slot="targetAmount" slot-scope="scope"
        >
        <amount-input type='Y' placeholder="请输入" v-model="scope.row.targetAmount" v-if="type!=='details'"></amount-input><div v-else>{{scope.row.targetAmount}}</div>
        </template>
      <template slot="caseStatus" slot-scope="scope"
        ><sz-input v-model="scope.row.caseStatus" placeholder="请输入" v-if="type!=='details'" maxlength="40"></sz-input
      ><div v-else>{{scope.row.caseStatus}}</div></template>
      <template slot="file" slot-scope="scope">
        <file-upload  v-for="(item, index) in scope.row.finFileInfos"
                :key="index"
                :item="item"
                :index="index"
                :scope="scope"
                @previewShow="previewShow"
                @Upload="uploadFile"
                :templateIndex='scope.$index'
                @deleteTemplate="deleteTemplate"
                :type='type'
                  ></file-upload>
      </template>
      <template slot="remark" slot-scope="scope"
        ><sz-input v-model="scope.row.remark" placeholder="请输入" v-if="type!=='details'"></sz-input
      ><div v-else>{{scope.row.remark}}</div></template>
      <template slot="action" slot-scope="scope">
          <icon-button
          @click="appendFile(scope.row, scope.$index)"
          content="追加文件"
          icon="iconfont iconzhuijia"
          v-if="type!=='details'"
        />
        <icon-button
          @click.prevent="del(scope.$index)"
          content="删除"
          icon="iconfont iconshanchu1"
          v-if="type !== 'details' && scope.row.dataSources !== 'TIANYANCHA'"
        />
      </template>
    </base-table>
    <pre-view :fileId="fileId" :isOpen='true' :fileType="fileType" :count="count" />
  </div>
</template>

<script>
import PreView from '@/components/pre-view/pre-view.vue'
import { allSupportFileTypes } from '@/components/pre-view/pre-view.js'
import { fileAPi } from '@/api/fileApi'
import {
  litigationApi
} from '@/api/companyApi'
import FileUpload from './file-button-group.vue'
import SzInput from '@/components/input/sz-input/sz-input.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import { tableConfig } from '../utils/addFinancialInformation.js'
// import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import AmountInput from '@/components/input/amount-input/amount-input.vue'
export default {
  components: { BaseTable, IconButton, SzInput, FileUpload, AmountInput, PreView },
  props: {
    litigationListData: Array,
    enterpriseName: String,
    type: String
  },
  data () {
    return {
      fileId: '',
      fileType: '',
      count: 0
    }
  },
  // 计算属性 类似于data概念
  computed: {
    litigationApi () {
      return litigationApi
    },
    tableData: {
      get () {
        return this.litigationListData
      },
      set (val) {
        this.$emit('update:litigationListData', val)
      }
    },
    tableConfig () {
      return tableConfig(this)
    }
  },
  // 监控data中的数据变化
  watch: {
    litigationListData: {
      handler (val) {

      }
    }
  },
  // 方法集合
  methods: {
    // 预览
    previewShow (data) {
      this.fileId =
        !data.pdfFileId || data.pdfFileId === '0'
          ? data.fileId
          : data.pdfFileId
      this.fileType = data.fileName ? data.fileName.split('.').pop() : ''
      this.fileType =
        !data.pdfFileId || data.pdfFileId === '0' ? this.fileType : 'pdf'
      this.fileType = this.fileType.toLowerCase()
      if (!this.fileType || !allSupportFileTypes.includes(this.fileType)) {
        this.warning(`不能预览的文件类型：[${this.fileType}]`)
        return
      }

      this.count++
    },
    // 追加文件
    appendFile (row, index) {
      console.log(row, 'row')
      if (!row.finFileInfos) {
        this.$set(row, 'finFileInfos', [])
      }
      if (row.finFileInfos.length > 9) {
        return this.warning('最多上传10份文件')
      }
      const data = row.finFileInfos.every(item => item.fileId !== '0')
      if (!data) {
        return this.warning('请先上传文件')
      }
      row.finFileInfos.push({ fileId: '0', fileName: '', addFile: true })
    },
    // 同步天眼查
    getSynchronization () {
      if (!this.enterpriseName) {
        this.warning('企业名称不能为空')
        return
      }
      const params = {
        name: this.enterpriseName
      }
      this.litigationApi.getSynchronization(params).then(res => {
        this.$emit('changeLitigationListData', res.data)
      })
    },
    // 导入文件
    importFile (param) {
      const formData = new FormData()
      const arr = param.file.name.split('.')
      const suffix = (arr[arr.length - 1]).toLowerCase()
      const lite = [
        'xls', 'xlsx']
      const flag = lite.some((val) => {
        return suffix === val
      })
      if (!flag) {
        this.warning('请上传xls,xlsx格式文件')
        return
      }
      formData.append('file', param.file)
      // formData.append('companyId ', this.$route.query.keyId)
      this.litigationApi.importFile(formData).then((res) => {
        if (res.code === '0') {
          this.success('文件上传成功')
          this.$emit('changeLitigationListData', res.data)
        } else {
          this.error('文件上传失败')
        }
      })
    },
    // 删除附件
    deleteTemplate (row, templateIndex, index) {
      this.tableData[templateIndex].finFileInfos.splice(index, 1)
    },
    uploadFile (param, row, templateIndex, index) {
      this.fileName = JSON.parse(JSON.stringify(param.file.name))
      if (this.fileName.length > 80) {
        return this.warning('文件名称小于80字符')
      }
      const EmojiReg = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g
      if (EmojiReg.test(param.file.name)) {
        this.warning('文件名不能存在Emoji')
        return false
      }
      const maxSize = 1024 * 10
      const fileSize = Math.round(param.file.size / 1024)
      if (fileSize - maxSize >= 0) {
        return this.warning('文件需小于10M')
      }

      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('belongCode', 'WSF')
      fileAPi.file(formData).then((res) => {
        if (res.success) {
          this.success('文件上传成功')
          console.log(res.data, templateIndex, index)
          this.$set(this.tableData[templateIndex].finFileInfos[index], 'fileName', res.data.fileName)
          this.$set(this.tableData[templateIndex].finFileInfos[index], 'fileId', res.data.keyId)
        } else {
          this.error('文件上传成功')
        }
      })
      // this.$set(this.tableData[index], 'fileName', data.fileName)
      // this.$set(this.tableData[index], 'fileId', data.keyId)
      // console.log(data, index)
    },
    del (index) {
      this.tableData.splice(index, 1)
    },
    add () {
      this.tableData.push({})
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {},
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.topBtn {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  justify-content: flex-end;
  margin-top: 10px;
  >div{
    &:last-child{
      margin-left: 5px;
    }
  }
}
.addBtn {
  background: #4A7CF0;
  width: 20px;
  height: 20px;
  color: #fff;
  text-align: center;
  line-height: 20px;
  cursor: pointer;

  &:hover {
    background-color: #6996FF;
  }
}
.btnGroup{
  display: flex;
}
</style>
